import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const SUB_EDITION_ENTITY_LIST_FRAGMENT = `
  fragment subEditionEntityListFragment on SubEdition {
    id
    uid
    __typename
    schemaCode
    name
    code
    archived
    startTimestamp
    endTimestamp
    logoFileResource {
      ...fileResourceBaseFragment
    }
    _isBookmarked(myUid: "%authUser%")
    _isFollowed(myUid: "%authUser%")
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
